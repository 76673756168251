import React from 'react';

const EmptyTaxonomyChart = (props: any) => {
  const generateLinearGradientId = (id: string) => {
    const extraIdPart = props.extraIdPart;
    return `${id}_${extraIdPart as string}`;
  };

  return (
    <svg width="164" height="141" viewBox="0 0 164 141" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M61.8025 88.032C62.5246 87.2962 62.5627 86.1235 61.9612 85.2827C59.1353 81.3317 57.4681 76.4655 57.4681 71.2024C57.4681 59.2844 66.0182 49.4009 77.2097 47.5725C78.2155 47.4082 79 46.5527 79 45.5148V24.4557C79 23.3228 78.0728 22.4135 76.9647 22.5066C52.3465 24.5738 33 45.5862 33 71.2024C33 83.2666 37.2911 94.3097 44.4031 102.834C45.1226 103.696 46.4065 103.719 47.192 102.918L61.8025 88.032Z"
        fill="#84C5FA"
      />
      <path
        d="M106.075 71.4195C106.075 59.4483 97.3651 49.5208 85.9646 47.6842C84.94 47.5192 84.1408 46.6599 84.1408 45.6174V24.4644C84.1408 23.3265 85.0854 22.4131 86.2142 22.5066C111.292 24.5831 131 45.6891 131 71.4195C131 98.526 109.128 120.5 82.1468 120.5C70.1556 120.5 59.1736 116.16 50.6721 108.958C49.8048 108.223 49.7793 106.905 50.5816 106.099L65.4755 91.1354C66.2068 90.4007 67.3708 90.3575 68.2111 90.9633C72.1357 93.7928 76.9476 95.4589 82.1468 95.4589C95.362 95.4589 106.075 84.6961 106.075 71.4195Z"
        fill="#B5DCFC"
      />
      <g filter={`url(#${generateLinearGradientId('filter0_d_1961_13690')})`}>
        <rect x="110" y="92.5" width="38" height="24" rx="4" fill="white" />
        <rect x="116" y="98.5" width="4" height="4" rx="2" fill="#B5DCFC" />
        <rect x="122" y="98.5" width="20" height="4" rx="2" fill="#EFF4FA" />
        <rect x="116" y="106.5" width="4" height="4" rx="2" fill="#84C5FA" />
        <rect x="122" y="106.5" width="10" height="4" rx="2" fill="#EFF4FA" />
      </g>
      <g filter={`url(#${generateLinearGradientId('filter1_d_1961_13690')})`}>
        <rect x="16" y="24.5" width="36" height="15.8597" rx="4" fill="white" />
        <rect x="20" y="28.5" width="16" height="7.85968" rx="3.92984" fill="#B5DCFC" />
        <path
          d="M40 30.2587C40 29.7086 40.1554 29.278 40.4662 28.9668C40.7841 28.6556 41.1903 28.5 41.6848 28.5C42.1792 28.5 42.5819 28.6556 42.8927 28.9668C43.2106 29.278 43.3695 29.7086 43.3695 30.2587C43.3695 30.8159 43.2106 31.2502 42.8927 31.5614C42.5819 31.8726 42.1792 32.0282 41.6848 32.0282C41.1903 32.0282 40.7841 31.8726 40.4662 31.5614C40.1554 31.2502 40 30.8159 40 30.2587ZM46.8026 28.6303L42.6384 36.2294H41.1974L45.351 28.6303H46.8026ZM41.6742 29.4119C41.2857 29.4119 41.0914 29.6942 41.0914 30.2587C41.0914 30.8304 41.2857 31.1163 41.6742 31.1163C41.8649 31.1163 42.0132 31.0475 42.1192 30.91C42.2252 30.7653 42.2781 30.5481 42.2781 30.2587C42.2781 29.6942 42.0768 29.4119 41.6742 29.4119ZM44.6517 34.5902C44.6517 34.0329 44.8071 33.6023 45.1179 33.2983C45.4358 32.9871 45.8419 32.8315 46.3364 32.8315C46.8309 32.8315 47.23 32.9871 47.5338 33.2983C47.8446 33.6023 48 34.0329 48 34.5902C48 35.1474 47.8446 35.5817 47.5338 35.8929C47.23 36.2041 46.8309 36.3597 46.3364 36.3597C45.8349 36.3597 45.4287 36.2041 45.1179 35.8929C44.8071 35.5817 44.6517 35.1474 44.6517 34.5902ZM46.3258 33.7434C45.9232 33.7434 45.7219 34.0257 45.7219 34.5902C45.7219 35.1619 45.9232 35.4478 46.3258 35.4478C46.7214 35.4478 46.9192 35.1619 46.9192 34.5902C46.9192 34.0257 46.7214 33.7434 46.3258 33.7434Z"
          fill="#B5DCFC"
        />
      </g>
      <defs>
        <filter
          id={generateLinearGradientId('filter0_d_1961_13690')}
          x="105"
          y="87.5"
          width="50"
          height="36"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1961_13690" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1961_13690" result="shape" />
        </filter>
        <filter
          id={generateLinearGradientId('filter1_d_1961_13690')}
          x="13"
          y="21.5"
          width="44"
          height="23.8596"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1961_13690" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1961_13690" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default EmptyTaxonomyChart;
