import React from 'react';

const EmptyState = (props: any) => {
  return (
    <svg width="169" height="150" viewBox="0 0 169 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="33" y="21.5" width="98" height="98" rx="6" fill="#E6F3FE" />
      <g filter="url(#filter0_d_1946_15447)">
        <rect x="22" y="36.5" width="118" height="32" rx="6" fill="#FAFCFF" />
      </g>
      <g filter="url(#filter1_d_1946_15447)">
        <rect x="15" y="44.5" width="134" height="32" rx="6" fill="#FCFEFF" />
      </g>
      <g filter="url(#filter2_d_1946_15447)">
        <rect x="6" y="52.5" width="150" height="50" rx="6" fill="white" />
        <g clip-path="url(#clip0_1946_15447)">
          <rect x="18" y="62.5" width="30" height="30" rx="4" fill="#B5DCFC" />
          <path
            d="M25 83.5V83.5C25 81.2909 26.7909 79.5 29 79.5H37C39.2091 79.5 41 81.2909 41 83.5V83.5"
            stroke="white"
            stroke-linecap="round"
          />
          <circle cx="33" cy="73.5" r="3" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <rect opacity="0.5" x="56" y="66.5" width="88" height="8" rx="4" fill="#B5DCFC" />
        <rect opacity="0.5" x="56" y="78.5" width="32" height="8" rx="4" fill="#B5DCFC" />
      </g>
      <g filter="url(#filter3_ddd_1946_15447)">
        <rect x="105" y="85.5" width="40" height="40" rx="6" fill="url(#paint0_linear_1946_15447)" />
        <path
          d="M120.694 114.419H115.184C114.575 114.419 114.083 113.925 114.083 113.318V108.91C114.083 108.301 114.576 107.809 115.184 107.809H120.693"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M127.303 95.6865V114.419H132.812C133.421 114.419 133.914 113.925 133.914 113.317V95.6865C133.914 95.0775 133.42 94.5852 132.812 94.5852H128.405C127.797 94.5852 127.303 95.0787 127.303 95.6865V95.6865Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M127.303 114.419H120.691V102.298C120.691 101.689 121.185 101.197 121.793 101.197H127.302"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1946_15447"
          x="16.6429"
          y="31.1429"
          width="130.857"
          height="44.8571"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.07143" dy="1.07143" />
          <feGaussianBlur stdDeviation="3.21429" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.09 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1946_15447" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1946_15447" result="shape" />
        </filter>
        <filter
          id="filter1_d_1946_15447"
          x="9.64286"
          y="39.1429"
          width="146.857"
          height="44.8571"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.07143" dy="1.07143" />
          <feGaussianBlur stdDeviation="3.21429" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.09 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1946_15447" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1946_15447" result="shape" />
        </filter>
        <filter
          id="filter2_d_1946_15447"
          x="1"
          y="47.5"
          width="162"
          height="62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1946_15447" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1946_15447" result="shape" />
        </filter>
        <filter
          id="filter3_ddd_1946_15447"
          x="81"
          y="61.5"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.625" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.635294 0 0 0 0 0.972549 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1946_15447" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0313726 0 0 0 0 0.470588 0 0 0 0 0.835294 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1946_15447" result="effect2_dropShadow_1946_15447" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.635294 0 0 0 0 0.972549 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_1946_15447" result="effect3_dropShadow_1946_15447" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1946_15447" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1946_15447"
          x1="64.5238"
          y1="51.2143"
          x2="145"
          y2="125.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.148294" stop-color="#0878D5" />
          <stop offset="0.696074" stop-color="#3A9FF2" />
          <stop offset="1" stop-color="#2699F7" />
        </linearGradient>
        <clipPath id="clip0_1946_15447">
          <rect width="30" height="30" fill="white" transform="translate(18 62.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyState;
