import React from 'react';

const SubscriptionModalBanner = () => (
  <svg width="253" height="136" viewBox="0 0 253 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2033_76875)">
      <rect x="5" y="5" width="150" height="50" rx="6" fill="white" />
      <g clip-path="url(#clip0_2033_76875)">
        <rect x="17" y="15" width="30" height="30" rx="4" fill="#B5DCFC" />
        <path
          d="M24 36V36C24 33.7909 25.7909 32 28 32H36C38.2091 32 40 33.7909 40 36V36"
          stroke="white"
          stroke-linecap="round"
        />
        <circle cx="32" cy="26" r="3" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <rect opacity="0.5" x="55" y="19" width="88" height="8" rx="4" fill="#B5DCFC" />
      <rect opacity="0.5" x="55" y="31" width="32" height="8" rx="4" fill="#B5DCFC" />
    </g>
    <g filter="url(#filter1_d_2033_76875)">
      <rect x="5" y="63" width="150" height="50" rx="6" fill="white" />
      <g clip-path="url(#clip1_2033_76875)">
        <rect x="17" y="73" width="30" height="30" rx="4" fill="#B5DCFC" />
        <path
          d="M24 94V94C24 91.7909 25.7909 90 28 90H36C38.2091 90 40 91.7909 40 94V94"
          stroke="white"
          stroke-linecap="round"
        />
        <circle cx="32" cy="84" r="3" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <rect opacity="0.5" x="55" y="77" width="88" height="8" rx="4" fill="#B5DCFC" />
      <rect opacity="0.5" x="55" y="89" width="32" height="8" rx="4" fill="#B5DCFC" />
    </g>
    <g filter="url(#filter2_d_2033_76875)">
      <rect x="102" y="37" width="144" height="92" rx="8" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M118 75.0601C118 61.5419 128.277 50.4277 141.434 49.1271C142.252 49.0462 142.937 49.7076 142.937 50.5304V60.8253C142.937 61.5784 142.358 62.197 141.619 62.3362C135.627 63.464 131.094 68.7318 131.094 75.0601C131.094 79.6841 133.514 83.742 137.155 86.0323C137.825 86.4533 138.134 87.2999 137.8 88.0174L133.482 97.2896C133.143 98.018 132.274 98.3338 131.57 97.9486C123.485 93.5277 118 84.9354 118 75.0601Z"
        fill="#84C5FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M135.481 97.9237C135.125 98.688 135.493 99.5975 136.298 99.8461C138.726 100.596 141.305 101 143.979 101C158.35 101 170 89.3324 170 74.9397C170 61.4215 159.723 50.3074 146.566 49.0067C145.748 48.9259 145.063 49.5872 145.063 50.41V60.7049C145.063 61.4581 145.642 62.0767 146.381 62.2158C152.373 63.3436 156.906 68.6115 156.906 74.9397C156.906 82.0903 151.118 87.8869 143.979 87.8869C143.163 87.8869 142.366 87.8113 141.592 87.6668C140.892 87.536 140.168 87.8576 139.867 88.5038L135.481 97.9237Z"
        fill="#B5DCFC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M203.94 49C217.458 49 228.572 59.2775 229.873 72.434C229.954 73.2517 229.292 73.9372 228.47 73.9372L218.175 73.9372C217.422 73.9372 216.803 73.3579 216.664 72.6188C215.536 66.627 210.268 62.0936 203.94 62.0936C199.316 62.0936 195.258 64.514 192.968 68.1553C192.547 68.8247 191.7 69.1336 190.983 68.7995L181.71 64.4823C180.982 64.1431 180.666 63.274 181.051 62.5696C185.472 54.4846 194.065 49 203.94 49Z"
        fill="#84C5FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M181.076 66.4807C180.312 66.1249 179.402 66.4932 179.154 67.2978C178.404 69.7255 178 72.305 178 74.9786C178 89.3498 189.668 101 204.06 101C217.578 101 228.693 90.7225 229.993 77.566C230.074 76.7483 229.413 76.0628 228.59 76.0628L218.295 76.0628C217.542 76.0628 216.923 76.6421 216.784 77.3812C215.656 83.373 210.389 87.9064 204.06 87.9064C196.91 87.9064 191.113 82.1184 191.113 74.9786C191.113 74.1633 191.189 73.3656 191.333 72.5922C191.464 71.8923 191.142 71.1676 190.496 70.8668L181.076 66.4807Z"
        fill="#B5DCFC"
      />
      <rect x="118" y="107.759" width="7.24138" height="7.24138" rx="3.62069" fill="#84C5FA" />
      <rect opacity="0.5" x="127.655" y="108.966" width="38.6207" height="4.82759" rx="2.41379" fill="#B5DCFC" />
      <rect x="178" y="107.759" width="7.24138" height="7.24138" rx="3.62069" fill="#84C5FA" />
      <rect opacity="0.5" x="187.655" y="108.966" width="38.6207" height="4.82759" rx="2.41379" fill="#B5DCFC" />
      <rect x="102.5" y="37.5" width="143" height="91" rx="7.5" stroke="url(#paint0_linear_2033_76875)" />
    </g>
    <defs>
      <filter
        id="filter0_d_2033_76875"
        x="0"
        y="0"
        width="162"
        height="62"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2033_76875" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2033_76875" result="shape" />
      </filter>
      <filter
        id="filter1_d_2033_76875"
        x="0"
        y="58"
        width="162"
        height="62"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2033_76875" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2033_76875" result="shape" />
      </filter>
      <filter
        id="filter2_d_2033_76875"
        x="97"
        y="32"
        width="156"
        height="104"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2033_76875" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2033_76875" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_2033_76875"
        x1="102"
        y1="37"
        x2="231.015"
        y2="142.274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5FAFF" />
        <stop offset="1" stop-color="#F5FAFF" />
      </linearGradient>
      <clipPath id="clip0_2033_76875">
        <rect width="30" height="30" fill="white" transform="translate(17 15)" />
      </clipPath>
      <clipPath id="clip1_2033_76875">
        <rect width="30" height="30" fill="white" transform="translate(17 73)" />
      </clipPath>
    </defs>
  </svg>
);

export default SubscriptionModalBanner;
