import React from 'react';

const EmptyListJourney = (props: any) => {
  return (
    <svg width="164" height="141" viewBox="0 0 164 141" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="33" y="21.5" width="98" height="98" rx="6" fill="#E6F3FE" />
      <rect x="14.5" y="29" width="115" height="73" rx="5.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26 59.5487C26 48.6302 34.301 39.6534 44.9275 38.6028C45.5879 38.5375 46.1416 39.0717 46.1416 39.7363V48.0514C46.1416 48.6597 45.6737 49.1593 45.0767 49.2717C40.2372 50.1826 36.5756 54.4375 36.5756 59.5487C36.5756 63.2835 38.5306 66.5611 41.4716 68.4109C42.0123 68.751 42.2618 69.4347 41.9919 70.0142L38.5049 77.5033C38.231 78.0917 37.529 78.3467 36.9601 78.0356C30.4299 74.4649 26 67.5249 26 59.5487Z"
        fill="#84C5FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.1192 78.0153C39.8318 78.6326 40.1292 79.3672 40.7791 79.568C42.74 80.1738 44.8234 80.5 46.9828 80.5C58.5903 80.5 68.0001 71.0762 68.0001 59.4513C68.0001 48.5328 59.6991 39.556 49.0726 38.5054C48.4122 38.4401 47.8585 38.9743 47.8585 39.6389V47.954C47.8585 48.5623 48.3265 49.0619 48.9234 49.1743C53.7629 50.0852 57.4245 54.34 57.4245 59.4513C57.4245 65.2267 52.7496 69.9086 46.9828 69.9086C46.3243 69.9086 45.68 69.8476 45.0554 69.7308C44.4901 69.6252 43.9048 69.885 43.6617 70.4069L40.1192 78.0153Z"
        fill="#B5DCFC"
      />
      <path
        d="M39.9149 50.8439C40.2302 51.1534 40.7328 51.1697 41.0931 50.912C42.7864 49.7009 44.8719 48.9863 47.1275 48.9863C52.2353 48.9863 56.471 52.6507 57.2546 57.447C57.3251 57.8781 57.6917 58.2143 58.1365 58.2143L67.1619 58.2143C67.6474 58.2143 68.0371 57.8169 67.9972 57.342C67.1112 46.7914 58.1059 38.5 47.1275 38.5C41.9572 38.5 37.2244 40.339 33.5713 43.3871C33.2018 43.6954 33.192 44.2457 33.535 44.5823L39.9149 50.8439Z"
        fill="#84C5FA"
      />
      <path
        d="M47.0345 69.8177C52.165 69.8177 56.4197 66.085 57.2068 61.199C57.2775 60.7599 57.6458 60.4174 58.0925 60.4174L67.1581 60.4174C67.6458 60.4174 68.0372 60.8222 67.9972 61.306C67.1073 72.0537 58.0618 80.4999 47.0345 80.4999C35.4174 80.4999 26 71.1261 26 59.5629C26 54.4237 27.8601 49.7172 30.9467 46.0737C31.2616 45.702 31.8266 45.6911 32.172 46.0349L38.5848 52.418C38.8997 52.7314 38.9182 53.2303 38.6586 53.5904C37.4459 55.2724 36.7319 57.3346 36.7319 59.5629C36.7319 65.2265 41.3445 69.8177 47.0345 69.8177Z"
        fill="#B5DCFC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M96.9513 38.5C107.87 38.5 116.847 46.801 117.897 57.4275C117.962 58.0879 117.428 58.6416 116.764 58.6416L108.449 58.6416C107.84 58.6416 107.341 58.1737 107.228 57.5767C106.317 52.7372 102.063 49.0756 96.9512 49.0756C93.2165 49.0756 89.9389 51.0306 88.0891 53.9716C87.749 54.5123 87.0653 54.7618 86.4858 54.4919L78.9967 51.0049C78.4083 50.731 78.1533 50.029 78.4644 49.4601C82.0351 42.9299 88.9751 38.5 96.9513 38.5Z"
        fill="#84C5FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.4847 52.6192C77.8674 52.3318 77.1328 52.6292 76.932 53.2791C76.3262 55.24 76 57.3234 76 59.4828C76 71.0903 85.4238 80.5001 97.0487 80.5001C107.967 80.5001 116.944 72.1991 117.995 61.5726C118.06 60.9122 117.526 60.3585 116.861 60.3585L108.546 60.3585C107.938 60.3585 107.438 60.8265 107.326 61.4234C106.415 66.2629 102.16 69.9245 97.0487 69.9245C91.2733 69.9245 86.5914 65.2496 86.5914 59.4828C86.5914 58.8243 86.6524 58.18 86.7692 57.5554C86.8748 56.9901 86.615 56.4048 86.0931 56.1617L78.4847 52.6192Z"
        fill="#B5DCFC"
      />
      <path
        d="M88.3439 66.5851C88.6534 66.2698 88.6697 65.7672 88.412 65.4069C87.2009 63.7136 86.4863 61.6281 86.4863 59.3725C86.4863 54.2647 90.1507 50.029 94.947 49.2454C95.3781 49.1749 95.7143 48.8083 95.7143 48.3635V39.3381C95.7143 38.8526 95.3169 38.4629 94.842 38.5028C84.2914 39.3888 76 48.3941 76 59.3725C76 64.5428 77.839 69.2756 80.8871 72.9287C81.1954 73.2982 81.7457 73.308 82.0823 72.965L88.3439 66.5851Z"
        fill="#84C5FA"
      />
      <path
        d="M107.318 59.4655C107.318 54.335 103.585 50.0803 98.699 49.2932C98.2599 49.2225 97.9174 48.8542 97.9174 48.4074V39.3419C97.9174 38.8542 98.3222 38.4628 98.806 38.5028C109.554 39.3927 118 48.4382 118 59.4655C118 71.0826 108.626 80.5 97.0629 80.5C91.9237 80.5 87.2172 78.6399 83.5737 75.5533C83.202 75.2384 83.1911 74.6734 83.5349 74.328L89.918 67.9152C90.2314 67.6003 90.7303 67.5818 91.0904 67.8414C92.7724 69.0541 94.8346 69.7681 97.0629 69.7681C102.726 69.7681 107.318 65.1555 107.318 59.4655Z"
        fill="#B5DCFC"
      />
      <rect x="26" y="86.5" width="6" height="6" rx="3" fill="#84C5FA" />
      <rect opacity="0.5" x="34" y="87.5" width="32" height="4" rx="2" fill="#B5DCFC" />
      <rect x="76" y="86.5" width="6" height="6" rx="3" fill="#84C5FA" />
      <rect opacity="0.5" x="84" y="87.5" width="32" height="4" rx="2" fill="#B5DCFC" />
      <rect x="14.5" y="29" width="115" height="73" rx="5.5" stroke="url(#paint0_linear_1948_12965)" />
      <g filter="url(#filter0_d_1948_12965)">
        <rect x="46" y="44.5" width="104" height="32" rx="6" fill="white" />
        <rect x="54" y="51.5" width="18" height="18" rx="2.77333" fill="#B5DCFC" />
        <path
          d="M58 65.5V64.5C58 63.3954 58.8954 62.5 60 62.5H66C67.1046 62.5 68 63.3954 68 64.5V65.5"
          stroke="white"
          stroke-linecap="round"
        />
        <circle cx="63" cy="58.5" r="2" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <rect opacity="0.5" x="80" y="53.8333" width="62" height="6" rx="3" fill="#B5DCFC" />
        <rect opacity="0.5" x="80" y="61.8333" width="22.5455" height="6" rx="3" fill="#B5DCFC" />
      </g>
      <g filter="url(#filter1_d_1948_12965)">
        <rect x="46" y="80.5" width="104" height="32" rx="6" fill="white" />
        <rect x="54" y="87.5" width="18" height="18" rx="2.77333" fill="#B5DCFC" />
        <path
          d="M58 101.5V100.5C58 99.3954 58.8954 98.5 60 98.5H66C67.1046 98.5 68 99.3954 68 100.5V101.5"
          stroke="white"
          stroke-linecap="round"
        />
        <circle cx="63" cy="94.5" r="2" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <rect opacity="0.5" x="80" y="89.8333" width="62" height="6" rx="3" fill="#B5DCFC" />
        <rect opacity="0.5" x="80" y="97.8333" width="22.5455" height="6" rx="3" fill="#B5DCFC" />
      </g>
      <defs>
        <filter
          id="filter0_d_1948_12965"
          x="41"
          y="39.5"
          width="116"
          height="44"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1948_12965" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1948_12965" result="shape" />
        </filter>
        <filter
          id="filter1_d_1948_12965"
          x="41"
          y="75.5"
          width="116"
          height="44"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1948_12965" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1948_12965" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1948_12965"
          x1="14"
          y1="28.5"
          x2="117.804"
          y2="113.33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5FAFF" />
          <stop offset="1" stop-color="#F5FAFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyListJourney;
