import React from 'react';

const FileChooseIcon = () => {
  return (
    <svg width="169" height="149" viewBox="0 0 169 149" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="33" y="21" width="98" height="98" rx="6" fill="#E6F3FE" />
      <g filter="url(#filter0_d_3844_36850)">
        <rect x="22" y="36" width="118" height="32" rx="6" fill="#FAFCFF" />
      </g>
      <g filter="url(#filter1_d_3844_36850)">
        <rect x="15" y="44" width="134" height="32" rx="6" fill="#FCFEFF" />
      </g>
      <g filter="url(#filter2_d_3844_36850)">
        <rect x="6" y="52" width="150" height="50" rx="6" fill="white" />
        <g clip-path="url(#clip0_3844_36850)">
          <rect x="18" y="62" width="30" height="30" rx="4" fill="#B5DCFC" />
          <path
            d="M34.5 69.5H28.5C28.1022 69.5 27.7206 69.658 27.4393 69.9393C27.158 70.2206 27 70.6022 27 71V83C27 83.3978 27.158 83.7794 27.4393 84.0607C27.7206 84.342 28.1022 84.5 28.5 84.5H37.5C37.8978 84.5 38.2794 84.342 38.5607 84.0607C38.842 83.7794 39 83.3978 39 83V74L34.5 69.5Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M34.5 69.5V74H39" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M33 81.5V77" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M30.75 79.25H35.25" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <rect opacity="0.5" x="56" y="66" width="88" height="8" rx="4" fill="#B5DCFC" />
        <rect opacity="0.5" x="56" y="78" width="32" height="8" rx="4" fill="#B5DCFC" />
      </g>
      <g filter="url(#filter3_ddd_3844_36850)">
        <rect x="105" y="85" width="40" height="40" rx="6" fill="url(#paint0_linear_3844_36850)" />
        <path
          d="M134.45 108.15V112.35C134.45 112.907 134.229 113.441 133.835 113.835C133.441 114.229 132.907 114.45 132.35 114.45H117.65C117.093 114.45 116.559 114.229 116.165 113.835C115.771 113.441 115.55 112.907 115.55 112.35V108.15"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M119.75 100.801L125 95.5508L130.25 100.801"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M125 95.5504V108.15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <filter
          id="filter0_d_3844_36850"
          x="16.6429"
          y="30.6429"
          width="130.857"
          height="44.8571"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.07143" dy="1.07143" />
          <feGaussianBlur stdDeviation="3.21429" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.09 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3844_36850" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3844_36850" result="shape" />
        </filter>
        <filter
          id="filter1_d_3844_36850"
          x="9.64286"
          y="38.6429"
          width="146.857"
          height="44.8571"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.07143" dy="1.07143" />
          <feGaussianBlur stdDeviation="3.21429" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.09 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3844_36850" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3844_36850" result="shape" />
        </filter>
        <filter
          id="filter2_d_3844_36850"
          x="1"
          y="47"
          width="162"
          height="62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3844_36850" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3844_36850" result="shape" />
        </filter>
        <filter
          id="filter3_ddd_3844_36850"
          x="81"
          y="61"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.625" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.635294 0 0 0 0 0.972549 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3844_36850" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0313726 0 0 0 0 0.470588 0 0 0 0 0.835294 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3844_36850" result="effect2_dropShadow_3844_36850" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.635294 0 0 0 0 0.972549 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_3844_36850" result="effect3_dropShadow_3844_36850" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_3844_36850" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_3844_36850"
          x1="64.5238"
          y1="50.7143"
          x2="145"
          y2="125"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.148294" stop-color="#0878D5" />
          <stop offset="0.696074" stop-color="#3A9FF2" />
          <stop offset="1" stop-color="#2699F7" />
        </linearGradient>
        <clipPath id="clip0_3844_36850">
          <rect width="30" height="30" fill="white" transform="translate(18 62)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileChooseIcon;
