import React from 'react';

const EmptyListIcon = (props: any) => {
  return (
    <svg width="164" height="141" viewBox="0 0 164 141" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_1941_6284"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="41"
        y="31"
        width="49"
        height="49"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M82.6226 72.1053C73.3132 81.4149 58.2264 81.4132 48.9152 72.1053C39.6161 62.8078 39.6161 47.7219 48.9273 38.414C58.2264 29.1165 73.3132 29.1165 82.6123 38.414C91.9217 47.7219 91.9217 62.8078 82.6226 72.1053"
          fill="white"
          fill-opacity="0.1"
        />
      </mask>
      <g mask="url(#mask0_1941_6284)">
        <rect
          width="6.0991"
          height="48.6352"
          transform="matrix(0.707161 0.707053 -0.707161 0.707053 70.6804 25.6782)"
          fill="url(#paint0_linear_1941_6284)"
        />
        <rect
          x="80.9558"
          y="30.6509"
          width="6.0991"
          height="55.6185"
          transform="rotate(45 80.9558 30.6509)"
          fill="url(#paint1_linear_1941_6284)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M82.6226 72.1061C73.3132 81.4159 58.2264 81.4142 48.9152 72.1061C39.6161 62.8084 39.6161 47.7222 48.9273 38.4142C58.2264 29.1165 73.3132 29.1165 82.6123 38.4142C91.9217 47.7222 91.9217 62.8084 82.6226 72.1061M88.2282 32.8038C75.8213 20.3987 55.7162 20.3987 43.3093 32.8038C30.9041 45.2071 30.8933 65.3217 43.2985 77.7267C54.5903 89.0132 72.2888 90.0311 84.7371 80.7641C85.953 79.8578 87.1239 78.8417 88.2408 77.7267C89.356 76.6099 90.3722 75.4392 91.2769 74.2235C100.545 61.7753 99.5182 44.0921 88.2282 32.8038Z"
        fill="url(#paint2_linear_1941_6284)"
      />
      <path
        d="M86.3518 79.4736C86.9968 78.9223 87.627 78.3394 88.2409 77.7266C89.356 76.6098 90.3723 75.4391 91.277 74.2234C91.4277 74.0209 91.5758 73.817 91.7211 73.6118L100.673 82.5626L95.0573 88.1777L86.3518 79.4736Z"
        fill="#B5DCFC"
      />
      <path
        d="M93.3965 94.9398C91.8457 93.3892 91.8457 90.8752 93.3965 89.3246L101.82 80.9019C103.371 79.3514 105.886 79.3514 107.436 80.9019L127.092 100.555C130.969 104.431 130.969 110.716 127.092 114.593C123.215 118.469 116.93 118.469 113.052 114.593L93.3965 94.9398Z"
        fill="url(#paint3_radial_1941_6284)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_6284"
          x1="3.04955"
          y1="0"
          x2="3.04955"
          y2="48.6352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E6F3FE" />
          <stop offset="1" stop-color="white" stop-opacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1941_6284"
          x1="84.0054"
          y1="30.6509"
          x2="84.0054"
          y2="86.2694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E6F3FE" />
          <stop offset="1" stop-color="white" stop-opacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1941_6284"
          x1="110.94"
          y1="87.137"
          x2="31.0286"
          y2="36.9985"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E6F3FE" />
          <stop offset="1" stop-color="#B5DCFC" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_1941_6284"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(153.54 128.305) rotate(-146.936) scale(71.5991 39.0618)"
        >
          <stop stop-color="#0878D5" />
          <stop offset="1" stop-color="#3AA2F8" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default EmptyListIcon;
