import styled from 'styled-components';

import FilterButton from '~/components/layout/ListHeader/components/FilterButton';
import { ThemeProp } from '~/theme/Themes';

export const StyledFilterButton = styled(FilterButton)`
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    display: none;
  }
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.smallMobile}) {
    display: flex;
    .nextui-button {
      border-radius: 2.5rem;
    }
  }
`;
