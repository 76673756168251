import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LogoIcon } from '~/overwrites/assets/logo.svg';

export const LogoContainer = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  margin: auto;
  width: 8rem;
  height: 8rem;
`;

const LoadingAnimation = () => {
  return (
    <LogoContainer>
      <LogoIcon />
    </LogoContainer>
  );
};

export default LoadingAnimation;
