import { commonTheme } from '~/theme/CommonTheming';
import { ThemeType } from '~/theme/Themes';

export const lightTheme: ThemeType = {
  name: 'Light',
  colors: {
    primaryLight: '#EAF6FC',
    p400: '#3AA2F8',
    secondary: '#DCF400',
    neon: '#11BA5D',
    background: '#F5F8FA',
    selection: '#F5FAFF',
    lightBlackBackground: '#223445',
    lightGreenBackground: '#ECF9F2',
    tableHeaderBackground: '#FBFBFB',
    secondaryGraph: '#3AA2F8',
    disabledPrimaryBackground: '#E1D3EE',
    red: '#ED0A34',
    darkRed: '#EB4747',
    errorRed: '#F31260',
    warningYellow: '#FABF1C',
    success: '#3CA540',
    graph: [
      '#065EA7',
      '#1A6AAF',
      '#2575b7',
      '#2F81BF',
      '#378CC6',
      '#3F98CE',
      '#46A4D6',
      '#4DB1DE',
      '#55BFE7',
      '#EAEAEA',
    ],
    primary: {
      c900: '#032745',
      c800: '#043D6C',
      c700: '#054880',
      c600: '#065EA7',
      c500: '#0878D5',
      c400: '#3AA2F8',
      c300: '#84C5FA',
      c200: '#B5DCFC',
      c100: '#E6F3FE',
      c050: '#F5FAFF',
    },
    neutrals: {
      black: '#2D2D2D',
      white: '#FFFFFF',
      c500: '#575757',
      c400: '#818181',
      c300: '#ABABAB',
      c200: '#D5D5D5',
      c100: '#EAEAEA',
      c050: '#F4F4F4',
    },
    system: {
      error: {
        c800: '#750E13',
        c700: '#A2191F',
        c600: '#DA1E28',
        c500: '#ef4444',
        c400: '#FA4D56',
        c300: '#FF8389',
        c200: '#FFB3B8',
        c100: '#FFD7D9',
        c050: '#FFF1F1',
      },
      warning: {
        c500: '#eab308',
        c300: '#fde047',
        c100: '#fef9c3',
      },
      success: {
        c900: '#022D0D',
        c800: '#044317',
        c700: '#0E6027',
        c600: '#198038',
        c500: '#24A148',
        c400: '#42BE65',
        c300: '#6FDC8C',
        c200: '#A7F0BA',
        c100: '#D1FAE5',
        c050: '#EDFDF5',
      },
      information: {
        c500: '#3b82f6',
        c300: '#bfdbfe',
        c100: '#dbeafe',
      },
      overlay: 'rgba(156, 163, 175, 0.4)',
    },
    modal: {
      startGradient: '#3AA2F8',
      endGradient: '#84C5FA',
      background: '#F5F8FA',
    },
  },
  gradients: {
    primary: 'linear-gradient(90deg, #3AA2F8 -0.29%, #84C5FA 100%)',
    overflowGradient: 'linear-gradient(270deg, #ffffff 0%, #ffffff00 100%)',
    shimmer: 'linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%)',
    shimmerPrimary: 'linear-gradient(to right, #9E61CE 8%, #A348D0 18%, #9E61CE 33%)',
    shimmerDark: 'linear-gradient(to right, #D4D8DE 8%, #E1E3E7 18%, #D4D8DE 33%)',
    welcome: 'linear-gradient(322.22deg, #6915bf 52.87%, #8b22c9 100%)',
    selection: 'transparent',
  },
  fonts: {
    primary: 'Roboto Flex',
    secondary: 'Manrope',
  },
  borderRadius: '8px',
  ...commonTheme,
};

export const darkTheme: ThemeType = {
  ...lightTheme,
  name: 'Dark',
  gradients: {
    ...lightTheme.gradients,
    shimmer: 'linear-gradient(to right, #4a5064 8%, #3c4456 18%, #4a5064 100%)',
  },
};
