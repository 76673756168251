import React from 'react';

interface LogoIconProps {
  gradientId?: string;
}

// eslint-disable-next-line no-empty-pattern
const LogoIcon = ({}: LogoIconProps) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2129_945)">
        <path
          d="M19.0022 33.9406C19.0348 33.9081 19.0637 33.8683 19.1034 33.8394C19.2408 33.7237 19.3999 33.6405 19.559 33.5863L19.8229 22.9681V16.9016C19.6204 16.869 19.4252 16.7895 19.2589 16.6521L9.06006 25.7193C9.21913 25.9869 9.26252 26.2942 9.19383 26.5834L18.9986 33.937L19.0022 33.9406Z"
          fill="white"
        />
        <path
          d="M14.0854 13.4419L18.9769 15.1881C19.0456 15.0615 19.136 14.9459 19.2517 14.8482C19.4216 14.7072 19.6204 14.6241 19.8229 14.5915V9.61324C19.3529 9.56624 18.8974 9.34571 18.572 8.95525C18.4491 8.81064 18.3623 8.64795 18.29 8.47803L9.32397 11.2727C9.34205 11.3775 9.34928 11.486 9.34205 11.5908L14.0854 13.4383V13.4419Z"
          fill="white"
        />
        <path
          d="M8.8864 12.4694C8.69479 12.6284 8.47064 12.7224 8.23926 12.755V25.1664C8.45618 25.1989 8.65864 25.2929 8.83217 25.4448L19.0238 16.3811C18.8575 16.1208 18.8032 15.8208 18.8575 15.5315L13.2899 13.5431L9.13586 12.1765C9.06717 12.285 8.98763 12.3826 8.88279 12.4694H8.8864Z"
          fill="white"
        />
        <path
          d="M36.9776 30.6003C40.8388 24.4181 41.0593 16.486 37.3536 10.0471L33.2357 11.3993C33.261 11.7933 33.1056 12.1946 32.7802 12.4694C32.5886 12.6285 32.3644 12.7225 32.1331 12.755V25.1664C32.4042 25.2098 32.6609 25.34 32.8525 25.5677C33.1996 25.9835 33.2068 26.5619 32.914 26.9849L36.9776 30.6003Z"
          fill="white"
        />
        <path
          d="M20.7483 16.6521C20.5784 16.7895 20.3832 16.8726 20.1843 16.9016V21.9775L20.5206 33.6152C20.6977 33.6875 20.864 33.7924 21.005 33.9406L30.8098 26.587C30.7411 26.2942 30.7881 25.9833 30.9435 25.723L20.7447 16.6557L20.7483 16.6521Z"
          fill="white"
        />
        <path
          d="M20.1843 5.90026C20.6543 5.94726 21.1099 6.1678 21.4352 6.55825C21.8148 7.01378 21.9341 7.59585 21.8221 8.13454L30.7845 10.9292C30.8604 10.7665 30.9725 10.6147 31.1207 10.4917C31.6666 10.0326 32.4801 10.1049 32.9392 10.6544C32.9501 10.6689 32.9573 10.6833 32.9681 10.6978L36.8293 9.19383C36.381 8.49969 35.8929 7.82 35.347 7.16924C31.4352 2.49101 25.8387 0.057892 20.1843 0.00366211V5.89665V5.90026Z"
          fill="white"
        />
        <path
          d="M7.03549 10.6941C7.09333 10.6218 7.15479 10.5531 7.23072 10.4917C7.77663 10.0325 8.59008 10.1048 9.04923 10.6544C9.12153 10.7411 9.17576 10.8351 9.22276 10.9328L18.1888 8.13811C18.055 7.48735 18.2647 6.78236 18.8106 6.32683C19.1107 6.07737 19.465 5.93637 19.8301 5.90022V0C15.358 0.0361533 10.8714 1.56905 7.17287 4.66016C5.57851 5.99422 4.24445 7.5235 3.17432 9.18655L7.0391 10.6941H7.03549Z"
          fill="white"
        />
        <path
          d="M32.6644 27.2486C32.1691 27.6282 31.4568 27.5595 31.0519 27.0751C31.0158 27.0317 30.9868 26.9811 30.9543 26.9341L21.2255 34.2298C21.5545 34.8083 21.4351 35.5566 20.9037 36.0013C20.8061 36.0809 20.7012 36.1423 20.5928 36.1929L20.7048 39.9999C25.0035 39.8517 29.284 38.3296 32.8379 35.3542C34.4069 34.0418 35.7193 32.5415 36.7786 30.9146L32.6607 27.2522L32.6644 27.2486Z"
          fill="white"
        />
        <path
          d="M19.4903 36.2221C19.277 36.1389 19.0782 36.0088 18.9227 35.8208C18.5359 35.358 18.4997 34.7217 18.7817 34.2264L9.05286 26.9307C8.98778 27.0355 8.91186 27.1367 8.81063 27.2199C8.38041 27.5778 7.76942 27.5778 7.34281 27.2488L3.23218 30.9039C3.66602 31.5691 4.13963 32.2199 4.66023 32.8417C8.47441 37.4007 13.8902 39.8265 19.3963 39.9965L19.4903 36.2221Z"
          fill="white"
        />
        <path
          d="M7.87417 25.1664V12.755C7.57048 12.7116 7.27764 12.5634 7.06433 12.3103C6.8438 12.0464 6.7498 11.721 6.76788 11.4029L2.6464 10.0471C-0.965311 16.3378 -0.929158 24.2698 3.03324 30.6003L7.09687 26.9886C6.75703 26.4969 6.83657 25.8172 7.30295 25.4231C7.47287 25.2821 7.67171 25.199 7.87417 25.1664Z"
          fill="white"
        />
        <path
          d="M31.2003 25.4195C31.3702 25.2785 31.5691 25.1953 31.7715 25.1628V12.7514C31.4679 12.708 31.175 12.5597 30.9617 12.3067C30.9256 12.2633 30.9002 12.2199 30.8713 12.1765L26.7173 13.5431L21.1497 15.5315C21.2039 15.8244 21.1497 16.1281 20.9834 16.3811L31.175 25.4412C31.175 25.4412 31.1895 25.4267 31.1967 25.4195H31.2003Z"
          fill="white"
        />
        <path
          d="M21.7172 8.48164C21.6051 8.74556 21.4388 8.99502 21.2038 9.19025C20.9038 9.4397 20.5495 9.5807 20.1843 9.61686V14.5952C20.4555 14.6385 20.7122 14.7687 20.9038 14.9965C20.9544 15.0579 20.9978 15.123 21.0339 15.1881L25.9219 13.4419L30.6688 11.5908C30.6579 11.4824 30.6688 11.3775 30.6832 11.2727L21.7172 8.47803V8.48164Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2129_945">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
