import React from 'react';

const AttributionCountIcon = (props: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#F4F4F4" />
      <g clip-path="url(#clip0_3347_25936)">
        <path
          d="M11.3084 17.5765C11.3214 17.5635 11.333 17.5476 11.3489 17.536C11.4038 17.4898 11.4675 17.4565 11.5311 17.4348L11.6367 13.1875V10.7609C11.5557 10.7479 11.4776 10.7161 11.4111 10.6611L7.33154 14.288C7.39517 14.395 7.41253 14.518 7.38505 14.6337L11.307 17.5751L11.3084 17.5765Z"
          fill="#30A5D9"
        />
        <path
          d="M9.34157 9.37714L11.2982 10.0756C11.3257 10.025 11.3618 9.97873 11.4081 9.93969C11.4761 9.88329 11.5556 9.85002 11.6366 9.83701V7.84569C11.4486 7.82689 11.2664 7.73867 11.1362 7.58249C11.087 7.52465 11.0523 7.45957 11.0234 7.3916L7.43701 8.50946C7.44424 8.5514 7.44713 8.59478 7.44424 8.63672L9.34157 9.37569V9.37714Z"
          fill="#30A5D9"
        />
        <path
          d="M7.26228 8.98823C7.18563 9.05186 7.09597 9.08946 7.00342 9.10247V14.067C7.09019 14.0801 7.17117 14.1177 7.24058 14.1784L11.3172 10.5529C11.2507 10.4488 11.229 10.3288 11.2507 10.2131L9.02366 9.41773L7.36206 8.87109C7.33458 8.91448 7.30277 8.95352 7.26083 8.98823H7.26228Z"
          fill="#30A5D9"
        />
        <path
          d="M18.4989 16.2398C20.0433 13.7669 20.1315 10.5941 18.6492 8.01855L17.0021 8.55941C17.0122 8.71704 16.95 8.87756 16.8199 8.98746C16.7432 9.05109 16.6536 9.08869 16.561 9.10171V14.0663C16.6695 14.0836 16.7722 14.1357 16.8488 14.2268C16.9876 14.3931 16.9905 14.6245 16.8734 14.7937L18.4989 16.2398Z"
          fill="#30A5D9"
        />
        <path
          d="M12.0068 10.6611C11.9389 10.7161 11.8608 10.7493 11.7812 10.7609V12.7913L11.9157 17.4464C11.9866 17.4753 12.0531 17.5172 12.1095 17.5765L16.0314 14.6351C16.004 14.518 16.0228 14.3936 16.0849 14.2895L12.0054 10.6626L12.0068 10.6611Z"
          fill="#30A5D9"
        />
        <path
          d="M11.7812 6.36059C11.9692 6.37939 12.1515 6.46761 12.2816 6.62379C12.4335 6.806 12.4812 7.03883 12.4363 7.2543L16.0213 8.37216C16.0517 8.30709 16.0965 8.24635 16.1558 8.19718C16.3742 8.01352 16.6995 8.04244 16.8832 8.26226C16.8875 8.26804 16.8904 8.27383 16.8948 8.27961L18.4392 7.67802C18.2599 7.40036 18.0647 7.12849 17.8463 6.86819C16.2816 4.99689 14.043 4.02365 11.7812 4.00195V6.35915V6.36059Z"
          fill="#30A5D9"
        />
        <path
          d="M6.52201 8.27766C6.54515 8.24873 6.56973 8.22126 6.6001 8.19667C6.81846 8.01301 7.14384 8.04194 7.3275 8.26175C7.35643 8.29646 7.37812 8.33406 7.39692 8.3731L10.9833 7.25524C10.9298 6.99494 11.0137 6.71294 11.2321 6.53073C11.3521 6.43095 11.4938 6.37455 11.6399 6.36009V4C9.851 4.01446 8.05635 4.62762 6.57696 5.86406C5.93922 6.39769 5.40559 7.0094 4.97754 7.67462L6.52345 8.27766H6.52201Z"
          fill="#30A5D9"
        />
        <path
          d="M16.7735 14.8993C16.5753 15.0511 16.2904 15.0236 16.1285 14.8298C16.114 14.8125 16.1025 14.7922 16.0894 14.7734L12.1979 17.6917C12.3295 17.9231 12.2818 18.2225 12.0692 18.4003C12.0301 18.4322 11.9882 18.4567 11.9448 18.477L11.9897 19.9998C13.7091 19.9405 15.4213 19.3316 16.8429 18.1415C17.4705 17.6165 17.9954 17.0164 18.4192 16.3656L16.772 14.9007L16.7735 14.8993Z"
          fill="#30A5D9"
        />
        <path
          d="M11.5037 18.489C11.4184 18.4558 11.3389 18.4037 11.2767 18.3285C11.122 18.1434 11.1075 17.8889 11.2203 17.6908L7.32876 14.7725C7.30273 14.8144 7.27236 14.8549 7.23187 14.8882C7.05978 15.0313 6.81538 15.0313 6.64474 14.8997L5.00049 16.3618C5.17402 16.6278 5.36347 16.8882 5.57171 17.1369C7.09738 18.9605 9.26368 19.9308 11.4661 19.9988L11.5037 18.489Z"
          fill="#30A5D9"
        />
        <path
          d="M6.85728 14.0663V9.10171C6.73581 9.08435 6.61867 9.02506 6.53335 8.92383C6.44514 8.81827 6.40754 8.68811 6.41477 8.56085L4.76618 8.01855C3.32149 10.5348 3.33595 13.7076 4.92091 16.2398L6.54637 14.7951C6.41043 14.5985 6.44224 14.3266 6.6288 14.169C6.69676 14.1126 6.7763 14.0793 6.85728 14.0663Z"
          fill="#30A5D9"
        />
        <path
          d="M16.1878 14.1683C16.2558 14.1119 16.3353 14.0786 16.4163 14.0656V9.10103C16.2949 9.08368 16.1777 9.02438 16.0924 8.92315C16.0779 8.9058 16.0678 8.88845 16.0562 8.87109L14.3946 9.41773L12.1676 10.2131C12.1893 10.3302 12.1676 10.4517 12.1011 10.5529L16.1777 14.177C16.1777 14.177 16.1835 14.1712 16.1864 14.1683H16.1878Z"
          fill="#30A5D9"
        />
        <path
          d="M12.3944 7.39305C12.3496 7.49862 12.2831 7.5984 12.1891 7.67649C12.069 7.77627 11.9273 7.83267 11.7812 7.84713V9.83846C11.8897 9.85581 11.9924 9.90787 12.069 9.99898C12.0893 10.0236 12.1066 10.0496 12.1211 10.0756L14.0763 9.37714L15.975 8.63672C15.9707 8.59334 15.975 8.5514 15.9808 8.50946L12.3944 7.3916V7.39305Z"
          fill="#30A5D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_3347_25936">
          <rect width="16" height="16" fill="white" transform="translate(3.70752 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AttributionCountIcon;
