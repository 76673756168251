import React from 'react';

const EmptyListIntegrationsIcon = (props: any) => {
  return (
    <svg width="169" height="150" viewBox="0 0 169 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="33" y="21.5" width="98" height="98" rx="6" fill="#E6F3FE" />
      <g filter="url(#filter0_d_1946_15492)">
        <rect x="22" y="36.5" width="118" height="32" rx="6" fill="#FAFCFF" />
      </g>
      <g filter="url(#filter1_d_1946_15492)">
        <rect x="15" y="44.5" width="134" height="32" rx="6" fill="#FCFEFF" />
      </g>
      <g filter="url(#filter2_d_1946_15492)">
        <rect x="6" y="52.5" width="150" height="50" rx="6" fill="white" />
        <g clip-path="url(#clip0_1946_15492)">
          <rect x="18" y="62.5" width="30" height="30" rx="4" fill="#B5DCFC" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M38.7973 85.0013H27.2025C26.2813 85.0013 25.5352 84.2552 25.5352 83.3339V74.1635C25.5352 73.2422 26.2813 72.4961 27.2025 72.4961H38.7973C39.7186 72.4961 40.4647 73.2422 40.4647 74.1635V83.3339C40.4647 84.2552 39.7177 85.0013 38.7973 85.0013Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.0002 75C34.1515 75 35.0844 75.9329 35.0844 77.0842C35.0844 78.2355 34.1515 79.1684 33.0002 79.1684C31.8489 79.1684 30.916 78.2355 30.916 77.0842C30.916 75.9329 31.8489 75 33.0002 75Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M36.7512 82.5005C36.642 82.2262 36.4727 81.9795 36.256 81.7786V81.7786C35.89 81.4384 35.4106 81.25 34.9104 81.25C34.0767 81.25 31.9225 81.25 31.0888 81.25C30.5886 81.25 30.1101 81.4392 29.7433 81.7786V81.7786C29.5265 81.9795 29.3573 82.2262 29.248 82.5005"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.9824 72.4971V71.6635C26.9824 70.7422 27.7286 69.9961 28.6498 69.9961H37.3492C38.2705 69.9961 39.0166 70.7422 39.0166 71.6635V72.4971"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <rect opacity="0.5" x="56" y="66.5" width="88" height="8" rx="4" fill="#B5DCFC" />
        <rect opacity="0.5" x="56" y="78.5" width="32" height="8" rx="4" fill="#B5DCFC" />
      </g>
      <g filter="url(#filter3_ddd_1946_15492)">
        <rect x="105" y="85.5" width="40" height="40" rx="6" fill="url(#paint0_linear_1946_15492)" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M123.2 102.781L128.254 105.699C128.813 106.021 129.004 106.735 128.682 107.293L127.952 108.557C127.227 109.813 125.621 110.243 124.365 109.518L121.838 108.059C120.582 107.334 120.151 105.728 120.877 104.472L121.606 103.208C121.928 102.65 122.642 102.459 123.2 102.781Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M123.745 103.09L125.058 100.816"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M127.715 105.383L129.028 103.108"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M124.345 115.972C129.512 116.293 134.141 112.799 135.249 107.743C136.357 102.686 133.614 97.5766 128.787 95.7065C123.96 93.8365 118.491 95.7645 115.903 100.248C114.451 102.758 114.058 105.744 114.811 108.545V108.545C115.373 110.626 116.737 112.4 118.604 113.479V113.479C119.346 113.906 120.293 113.652 120.722 112.911L123.104 108.785"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1946_15492"
          x="16.6429"
          y="31.1429"
          width="130.857"
          height="44.8571"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.07143" dy="1.07143" />
          <feGaussianBlur stdDeviation="3.21429" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.09 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1946_15492" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1946_15492" result="shape" />
        </filter>
        <filter
          id="filter1_d_1946_15492"
          x="9.64286"
          y="39.1429"
          width="146.857"
          height="44.8571"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.07143" dy="1.07143" />
          <feGaussianBlur stdDeviation="3.21429" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.09 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1946_15492" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1946_15492" result="shape" />
        </filter>
        <filter
          id="filter2_d_1946_15492"
          x="1"
          y="47.5"
          width="162"
          height="62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1946_15492" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1946_15492" result="shape" />
        </filter>
        <filter
          id="filter3_ddd_1946_15492"
          x="81"
          y="61.5"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.625" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.635294 0 0 0 0 0.972549 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1946_15492" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0313726 0 0 0 0 0.470588 0 0 0 0 0.835294 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1946_15492" result="effect2_dropShadow_1946_15492" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.635294 0 0 0 0 0.972549 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_1946_15492" result="effect3_dropShadow_1946_15492" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1946_15492" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1946_15492"
          x1="64.5238"
          y1="51.2143"
          x2="145"
          y2="125.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.148294" stop-color="#0878D5" />
          <stop offset="0.696074" stop-color="#3A9FF2" />
          <stop offset="1" stop-color="#2699F7" />
        </linearGradient>
        <clipPath id="clip0_1946_15492">
          <rect width="30" height="30" fill="white" transform="translate(18 62.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyListIntegrationsIcon;
