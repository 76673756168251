import React from 'react';

interface LogoIconProps {
  gradientId?: string;
}

// eslint-disable-next-line no-empty-pattern
const LogoIcon = ({}: LogoIconProps) => {
  return (
    <svg width="124" height="40" viewBox="0 0 124 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3350_105015)">
        <path
          d="M19.0017 33.9408C19.0343 33.9083 19.0632 33.8685 19.103 33.8396C19.2403 33.7239 19.3994 33.6408 19.5585 33.5865L19.8224 22.9683V16.9018C19.6199 16.8693 19.4247 16.7897 19.2584 16.6523L9.05957 25.7196C9.21864 25.9871 9.26203 26.2944 9.19334 26.5837L18.9981 33.9372L19.0017 33.9408Z"
          fill="white"
        />
        <path
          d="M14.0846 13.4424L18.9762 15.1886C19.0449 15.062 19.1352 14.9463 19.2509 14.8487C19.4209 14.7077 19.6197 14.6246 19.8222 14.592V9.61373C19.3522 9.56673 18.8966 9.34619 18.5713 8.95574C18.4483 8.81113 18.3616 8.64844 18.2893 8.47852L9.32324 11.2732C9.34132 11.378 9.34855 11.4865 9.34132 11.5913L14.0846 13.4387V13.4424Z"
          fill="white"
        />
        <path
          d="M8.8864 12.4686C8.69479 12.6277 8.47064 12.7217 8.23926 12.7542V25.1657C8.45618 25.1982 8.65864 25.2922 8.83217 25.444L19.0238 16.3804C18.8575 16.1201 18.8032 15.82 18.8575 15.5308L13.2899 13.5424L9.13586 12.1758C9.06717 12.2842 8.98763 12.3819 8.88279 12.4686H8.8864Z"
          fill="white"
        />
        <path
          d="M36.9774 30.6C40.8385 24.4178 41.0591 16.4858 37.3533 10.0469L33.2355 11.399C33.2608 11.7931 33.1053 12.1944 32.78 12.4691C32.5883 12.6282 32.3642 12.7222 32.1328 12.7548V25.1662C32.404 25.2096 32.6607 25.3397 32.8523 25.5675C33.1993 25.9832 33.2066 26.5617 32.9137 26.9847L36.9774 30.6Z"
          fill="white"
        />
        <path
          d="M20.7486 16.6523C20.5786 16.7897 20.3834 16.8729 20.1846 16.9018V21.9777L20.5208 33.6155C20.6979 33.6878 20.8643 33.7926 21.0052 33.9408L30.81 26.5873C30.7413 26.2944 30.7883 25.9835 30.9438 25.7232L20.7449 16.656L20.7486 16.6523Z"
          fill="white"
        />
        <path
          d="M20.1846 5.90051C20.6546 5.94751 21.1101 6.16804 21.4355 6.5585C21.8151 7.01403 21.9344 7.5961 21.8223 8.13478L30.7847 10.9294C30.8606 10.7667 30.9727 10.6149 31.1209 10.492C31.6669 10.0328 32.4803 10.1051 32.9395 10.6547C32.9503 10.6691 32.9575 10.6836 32.9684 10.698L36.8295 9.19407C36.3812 8.49993 35.8932 7.82025 35.3473 7.16949C31.4355 2.49125 25.8389 0.0581362 20.1846 0.00390625V5.89689V5.90051Z"
          fill="white"
        />
        <path
          d="M7.035 10.6941C7.09284 10.6218 7.15431 10.5531 7.23023 10.4917C7.77614 10.0325 8.58959 10.1048 9.04874 10.6544C9.12104 10.7411 9.17527 10.8351 9.22227 10.9328L18.1883 8.13811C18.0545 7.48735 18.2642 6.78236 18.8101 6.32683C19.1102 6.07737 19.4645 5.93637 19.8296 5.90022V0C15.3575 0.0361533 10.8709 1.56905 7.17238 4.66016C5.57802 5.99422 4.24397 7.5235 3.17383 9.18655L7.03861 10.6941H7.035Z"
          fill="white"
        />
        <path
          d="M32.6644 27.2481C32.1691 27.6277 31.4568 27.559 31.0519 27.0746C31.0158 27.0312 30.9868 26.9806 30.9543 26.9336L21.2255 34.2293C21.5545 34.8078 21.4351 35.5562 20.9037 36.0008C20.8061 36.0804 20.7012 36.1418 20.5928 36.1925L20.7048 39.9994C25.0035 39.8512 29.284 38.3291 32.8379 35.3537C34.4069 34.0413 35.7193 32.541 36.7786 30.9141L32.6607 27.2517L32.6644 27.2481Z"
          fill="white"
        />
        <path
          d="M19.4896 36.2211C19.2763 36.1379 19.0774 36.0078 18.922 35.8198C18.5351 35.357 18.499 34.7207 18.781 34.2254L9.05213 26.9297C8.98705 27.0345 8.91113 27.1358 8.8099 27.2189C8.37967 27.5768 7.76868 27.5768 7.34207 27.2478L3.23145 30.9029C3.66528 31.5682 4.13889 32.2189 4.6595 32.8407C8.47367 37.3997 13.8894 39.8256 19.3956 39.9955L19.4896 36.2211Z"
          fill="white"
        />
        <path
          d="M7.87392 25.1662V12.7548C7.57024 12.7114 7.2774 12.5631 7.06409 12.3101C6.84356 12.0462 6.74956 11.7208 6.76763 11.4026L2.64616 10.0469C-0.965555 16.3375 -0.929402 24.2696 3.033 30.6L7.09663 26.9883C6.75679 26.4966 6.83633 25.8169 7.3027 25.4229C7.47262 25.2819 7.67147 25.1987 7.87392 25.1662Z"
          fill="white"
        />
        <path
          d="M31.2003 25.4187C31.3702 25.2777 31.5691 25.1946 31.7715 25.162V12.7506C31.4679 12.7072 31.175 12.559 30.9617 12.3059C30.9256 12.2625 30.9002 12.2192 30.8713 12.1758L26.7173 13.5424L21.1497 15.5308C21.2039 15.8236 21.1497 16.1273 20.9834 16.3804L31.175 25.4404C31.175 25.4404 31.1895 25.426 31.1967 25.4187H31.2003Z"
          fill="white"
        />
        <path
          d="M21.7175 8.48213C21.6054 8.74605 21.4391 8.99551 21.2041 9.19074C20.904 9.44019 20.5497 9.58119 20.1846 9.61734V14.5957C20.4557 14.639 20.7124 14.7692 20.904 14.997C20.9546 15.0584 20.998 15.1235 21.0342 15.1886L25.9221 13.4424L30.669 11.5913C30.6582 11.4829 30.669 11.378 30.6835 11.2732L21.7175 8.47852V8.48213Z"
          fill="white"
        />
        <path d="M46.7061 18.329V8.26758H47.3713V18.329H46.7061Z" fill="white" />
        <path
          d="M58.3075 18.329L52.0096 9.29433V18.329H51.377V8.26758H52.0096L58.3075 17.2842V8.26758H58.9402V18.329H58.3075Z"
          fill="white"
        />
        <path
          d="M65.6647 8.84241V18.329H64.9995V8.84241H61.8867V8.26758H68.7739V8.84241H65.6611H65.6647Z"
          fill="white"
        />
        <path
          d="M71.7207 18.329V8.26758H76.887V8.84241H72.3859V12.8771H76.7062V13.452H72.3859V17.7578H76.934V18.3327H71.7207V18.329Z"
          fill="white"
        />
        <path
          d="M86.1165 10.9429C86.1165 11.6696 85.8743 12.2697 85.3898 12.747C84.9054 13.2242 84.2655 13.564 83.4701 13.7665L87.1541 18.329H86.3696L82.8049 13.9039C82.5337 13.9328 82.2445 13.9473 81.9444 13.9473H81.0695V18.329H80.4043V8.26758H82.6241C83.076 8.26758 83.5135 8.32181 83.9292 8.43388C84.3486 8.54596 84.721 8.71226 85.0464 8.9328C85.3718 9.15333 85.6321 9.43171 85.8237 9.76432C86.0153 10.0969 86.1093 10.491 86.1093 10.9429H86.1165ZM85.4513 10.9899C85.4513 10.6067 85.3681 10.2813 85.2018 10.0066C85.0355 9.7354 84.8186 9.51487 84.5511 9.34133C84.2835 9.17141 83.9799 9.04487 83.6364 8.96534C83.2929 8.8858 82.9423 8.84603 82.5807 8.84603H81.0695V13.3797H82.0059C82.4903 13.3797 82.9386 13.3254 83.358 13.2206C83.7774 13.1157 84.1426 12.9603 84.4535 12.7614C84.7644 12.559 85.0102 12.3095 85.1874 12.0131C85.3645 11.7166 85.4513 11.3768 85.4513 10.9935V10.9899Z"
          fill="white"
        />
        <path
          d="M95.4659 15.8105C95.4659 16.2046 95.3863 16.5661 95.2309 16.8987C95.0754 17.2313 94.8549 17.517 94.5729 17.7592C94.2909 18.0014 93.9547 18.1858 93.5606 18.3196C93.1665 18.4497 92.7363 18.5148 92.2627 18.5148C91.7891 18.5148 91.3335 18.4606 90.8346 18.3557C90.3357 18.2509 89.9055 18.1135 89.5439 17.94L89.7103 17.3796C90.1224 17.5603 90.5309 17.6977 90.9322 17.7953C91.3335 17.893 91.7674 17.94 92.2301 17.94C93.0147 17.94 93.6401 17.752 94.1029 17.3723C94.5656 16.9927 94.797 16.4794 94.797 15.825C94.797 15.4743 94.7247 15.1706 94.5873 14.9175C94.4463 14.6645 94.262 14.4403 94.0342 14.2451C93.8064 14.0499 93.5497 13.8763 93.2569 13.7245C92.9641 13.5726 92.664 13.4208 92.3494 13.2726C91.9771 13.0918 91.6336 12.911 91.3155 12.7375C90.9973 12.564 90.7262 12.3651 90.4984 12.1482C90.2706 11.9313 90.0971 11.689 89.9706 11.4143C89.844 11.1431 89.7826 10.8214 89.7826 10.449C89.7826 10.0477 89.8621 9.697 90.0176 9.40055C90.173 9.10409 90.3827 8.85825 90.643 8.66663C90.9033 8.47502 91.207 8.33041 91.5505 8.23641C91.8939 8.14241 92.2518 8.0918 92.6242 8.0918C93.0761 8.0918 93.5136 8.14241 93.9293 8.24364C94.3487 8.34487 94.7211 8.47864 95.0537 8.65217L94.9019 9.22701C94.5403 9.05709 94.168 8.91971 93.792 8.81848C93.416 8.71725 93.0327 8.66663 92.6531 8.66663C92.0204 8.66663 91.4926 8.81125 91.0732 9.09686C90.6539 9.38247 90.4478 9.82354 90.4478 10.4201C90.4478 10.7129 90.4912 10.9624 90.5743 11.1684C90.6611 11.3745 90.7876 11.5625 90.9612 11.7288C91.1311 11.8951 91.348 12.0542 91.6047 12.206C91.8614 12.3579 92.1651 12.5133 92.5194 12.6724C92.8411 12.8243 93.1774 12.9906 93.5244 13.1786C93.8715 13.3666 94.1896 13.5835 94.4753 13.8293C94.7609 14.0752 94.9995 14.3608 95.1839 14.6825C95.3719 15.0043 95.4622 15.3839 95.4622 15.8141L95.4659 15.8105Z"
          fill="white"
        />
        <path
          d="M98.792 18.329V8.26758H103.958V8.84241H99.4572V12.8771H103.778V13.452H99.4572V17.7578H104.005V18.3327H98.792V18.329Z"
          fill="white"
        />
        <path
          d="M114.23 9.05651C113.868 8.91551 113.492 8.81428 113.105 8.75282C112.718 8.69136 112.328 8.66244 111.934 8.66244C111.251 8.66244 110.632 8.7709 110.083 8.98782C109.533 9.20474 109.067 9.51565 108.684 9.91696C108.3 10.3183 108.008 10.8099 107.809 11.3812C107.606 11.956 107.505 12.5959 107.505 13.3009C107.505 14.0059 107.606 14.6567 107.809 15.2279C108.011 15.7955 108.3 16.2836 108.684 16.6849C109.067 17.0862 109.533 17.3971 110.083 17.614C110.632 17.8309 111.247 17.9394 111.934 17.9394C112.328 17.9394 112.718 17.9104 113.105 17.849C113.492 17.7875 113.868 17.6863 114.23 17.5453L114.349 18.0876C114.027 18.2286 113.648 18.3334 113.21 18.4057C112.773 18.4744 112.332 18.5106 111.887 18.5106C111.091 18.5106 110.383 18.3841 109.757 18.1238C109.132 17.8671 108.604 17.5091 108.17 17.05C107.736 16.5909 107.407 16.0413 107.18 15.405C106.952 14.7651 106.84 14.0637 106.84 13.2973C106.84 12.5308 106.952 11.8331 107.18 11.1968C107.407 10.5641 107.736 10.0146 108.17 9.55181C108.604 9.08905 109.132 8.72751 109.757 8.47082C110.383 8.21414 111.091 8.08398 111.887 8.08398C112.332 8.08398 112.769 8.12014 113.21 8.18883C113.648 8.26114 114.027 8.36598 114.349 8.50698L114.23 9.04928V9.05651Z"
          fill="white"
        />
        <path d="M120.466 8.84241V18.329H119.8V8.84241H116.688V8.26758H123.575V8.84241H120.462H120.466Z" fill="white" />
        <path d="M48.0551 23.0727V29.4284H47.6105V23.0727H45.5244V22.6895H50.1412V23.0727H48.0551Z" fill="white" />
        <path
          d="M52.1152 29.4281V22.6855H55.5787V23.0688H52.5635V25.773H55.4594V26.1563H52.5635V29.0413H55.6113V29.4245H52.1189L52.1152 29.4281Z"
          fill="white"
        />
        <path
          d="M62.4582 23.2138C62.2159 23.1198 61.9629 23.0512 61.7026 23.0114C61.4423 22.9716 61.182 22.9499 60.918 22.9499C60.4589 22.9499 60.0468 23.0222 59.678 23.1668C59.3092 23.3115 58.9983 23.5211 58.7416 23.7887C58.4849 24.0598 58.2897 24.3852 58.1559 24.772C58.0222 25.1553 57.9535 25.5855 57.9535 26.0591C57.9535 26.5327 58.0222 26.9702 58.1559 27.3498C58.2897 27.7294 58.4849 28.0584 58.7416 28.3259C58.9983 28.5971 59.3092 28.8031 59.678 28.9478C60.0468 29.0924 60.4589 29.1647 60.918 29.1647C61.182 29.1647 61.4423 29.143 61.7026 29.1032C61.9629 29.0634 62.2123 28.9948 62.4582 28.9008L62.5377 29.2659C62.3208 29.3599 62.0677 29.4322 61.7749 29.4792C61.482 29.5262 61.1856 29.5515 60.8891 29.5515C60.3577 29.5515 59.8804 29.4647 59.4611 29.2948C59.0417 29.1213 58.6874 28.8827 58.3982 28.5754C58.1089 28.2681 57.8884 27.8993 57.7366 27.4727C57.5847 27.0461 57.5088 26.5725 57.5088 26.0591C57.5088 25.5457 57.5847 25.0757 57.7366 24.6527C57.8884 24.2297 58.1089 23.861 58.3982 23.5501C58.6874 23.2391 59.0417 22.9969 59.4611 22.827C59.8804 22.6535 60.354 22.5703 60.8891 22.5703C61.1856 22.5703 61.482 22.5956 61.7749 22.6426C62.0677 22.6896 62.3244 22.7619 62.5377 22.8559L62.4582 23.2211V23.2138Z"
          fill="white"
        />
        <path
          d="M69.3239 29.4281V26.2105H65.2132V29.4281H64.7686V22.6855H65.2132V25.802H69.3239V22.6855H69.7686V29.4281H69.3239Z"
          fill="white"
        />
        <path
          d="M77.0468 29.4281L72.8241 23.3761V29.4281H72.3975V22.6855H72.8241L77.0468 28.7304V22.6855H77.4734V29.4281H77.0468Z"
          fill="white"
        />
        <path
          d="M86.0346 26.0588C86.0346 26.518 85.9623 26.959 85.8213 27.3784C85.6803 27.8014 85.4707 28.1702 85.1995 28.4919C84.9247 28.8137 84.5921 29.0704 84.2017 29.262C83.8112 29.4536 83.3665 29.5512 82.864 29.5512C82.3615 29.5512 81.9204 29.4536 81.5263 29.262C81.1323 29.0704 80.8033 28.8137 80.5285 28.4919C80.2537 28.1702 80.0477 27.8014 79.9067 27.3784C79.7657 26.9554 79.6934 26.518 79.6934 26.0588C79.6934 25.5997 79.7657 25.1586 79.9067 24.7392C80.0477 24.3162 80.2574 23.9475 80.5285 23.6257C80.7997 23.3039 81.1359 23.0472 81.5263 22.8556C81.9168 22.664 82.3615 22.5664 82.864 22.5664C83.3665 22.5664 83.8076 22.664 84.2017 22.8556C84.5921 23.0472 84.9247 23.3039 85.1995 23.6257C85.4743 23.9475 85.6803 24.3162 85.8213 24.7392C85.9623 25.1622 86.0346 25.5997 86.0346 26.0588ZM85.59 26.0588C85.59 25.6322 85.5285 25.2345 85.402 24.8585C85.2754 24.4825 85.0947 24.1535 84.8597 23.8715C84.6247 23.5895 84.3354 23.3654 83.9992 23.1991C83.663 23.0328 83.2834 22.9496 82.864 22.9496C82.4446 22.9496 82.0686 23.0328 81.7288 23.1991C81.3926 23.3654 81.1033 23.5895 80.8683 23.8715C80.6333 24.1535 80.4526 24.4825 80.326 24.8585C80.1995 25.2345 80.138 25.6322 80.138 26.0588C80.138 26.4854 80.1995 26.8831 80.326 27.2591C80.4526 27.6351 80.6297 27.9641 80.8683 28.2461C81.1033 28.5281 81.3926 28.7558 81.7288 28.9185C82.065 29.0848 82.4446 29.168 82.864 29.168C83.2834 29.168 83.6594 29.0848 83.9992 28.9185C84.3354 28.7522 84.6247 28.5281 84.8597 28.2461C85.0947 27.9641 85.2754 27.6351 85.402 27.2591C85.5249 26.8831 85.59 26.4854 85.59 26.0588Z"
          fill="white"
        />
        <path d="M88.2607 29.4281V22.6855H88.7054V29.0413H91.5796V29.4245H88.2607V29.4281Z" fill="white" />
        <path
          d="M99.3891 26.0588C99.3891 26.518 99.3168 26.959 99.1758 27.3784C99.0348 27.8014 98.8252 28.1702 98.554 28.4919C98.2792 28.8137 97.9466 29.0704 97.5562 29.262C97.1657 29.4536 96.721 29.5512 96.2185 29.5512C95.716 29.5512 95.2749 29.4536 94.8808 29.262C94.4868 29.0704 94.1578 28.8137 93.883 28.4919C93.6082 28.1702 93.4022 27.8014 93.2612 27.3784C93.1202 26.9554 93.0479 26.518 93.0479 26.0588C93.0479 25.5997 93.1202 25.1586 93.2612 24.7392C93.4022 24.3162 93.6118 23.9475 93.883 23.6257C94.1541 23.3039 94.4904 23.0472 94.8808 22.8556C95.2713 22.664 95.716 22.5664 96.2185 22.5664C96.721 22.5664 97.1621 22.664 97.5562 22.8556C97.9466 23.0472 98.2792 23.3039 98.554 23.6257C98.8288 23.9475 99.0348 24.3162 99.1758 24.7392C99.3168 25.1622 99.3891 25.5997 99.3891 26.0588ZM98.9445 26.0588C98.9445 25.6322 98.883 25.2345 98.7565 24.8585C98.6299 24.4825 98.4492 24.1535 98.2142 23.8715C97.9792 23.5895 97.6899 23.3654 97.3537 23.1991C97.0175 23.0328 96.6379 22.9496 96.2185 22.9496C95.7991 22.9496 95.4231 23.0328 95.0833 23.1991C94.7471 23.3654 94.4578 23.5895 94.2228 23.8715C93.9878 24.1535 93.8071 24.4825 93.6805 24.8585C93.554 25.2345 93.4925 25.6322 93.4925 26.0588C93.4925 26.4854 93.554 26.8831 93.6805 27.2591C93.8071 27.6351 93.9842 27.9641 94.2228 28.2461C94.4578 28.5281 94.7471 28.7558 95.0833 28.9185C95.4195 29.0848 95.7991 29.168 96.2185 29.168C96.6379 29.168 97.0139 29.0848 97.3537 28.9185C97.6899 28.7522 97.9792 28.5281 98.2142 28.2461C98.4492 27.9641 98.6299 27.6351 98.7565 27.2591C98.8794 26.8831 98.9445 26.4854 98.9445 26.0588Z"
          fill="white"
        />
        <path
          d="M106.526 23.2856C106.262 23.1771 105.984 23.094 105.687 23.0361C105.391 22.9783 105.08 22.9493 104.755 22.9493C104.295 22.9493 103.876 23.0253 103.493 23.1771C103.11 23.329 102.784 23.5423 102.51 23.8206C102.235 24.099 102.025 24.428 101.873 24.8185C101.721 25.2089 101.645 25.6391 101.645 26.12C101.645 26.6008 101.718 27.0166 101.859 27.3962C102 27.7758 102.206 28.094 102.477 28.3579C102.748 28.6218 103.074 28.8206 103.46 28.9616C103.844 29.099 104.277 29.1677 104.755 29.1677C104.863 29.1677 104.986 29.1641 105.131 29.1532C105.272 29.1424 105.413 29.1279 105.557 29.1135C105.702 29.099 105.836 29.0773 105.966 29.052C106.096 29.0267 106.215 29.0014 106.316 28.9652V26.5755H106.743V29.2364C106.454 29.3449 106.125 29.4244 105.756 29.475C105.387 29.5256 105.04 29.5509 104.708 29.5509C104.169 29.5509 103.681 29.4678 103.251 29.3015C102.82 29.1352 102.452 28.9038 102.148 28.6037C101.844 28.3036 101.613 27.9421 101.45 27.5191C101.288 27.0961 101.208 26.6297 101.208 26.1164C101.208 25.603 101.291 25.1185 101.461 24.6847C101.631 24.2509 101.87 23.8749 102.181 23.5603C102.491 23.2458 102.864 23.0036 103.298 22.8264C103.732 22.6493 104.216 22.5625 104.751 22.5625C105.094 22.5625 105.434 22.5914 105.763 22.6493C106.092 22.7071 106.378 22.7939 106.613 22.906L106.533 23.282L106.526 23.2856Z"
          fill="white"
        />
        <path d="M109.299 29.4281V22.6855H109.744V29.4281H109.299Z" fill="white" />
        <path
          d="M112.426 29.4281V22.6855H115.889V23.0688H112.874V25.773H115.77V26.1563H112.874V29.0413H115.922V29.4245H112.429L112.426 29.4281Z"
          fill="white"
        />
        <path
          d="M121.841 27.7399C121.841 28.0039 121.79 28.2461 121.685 28.4702C121.58 28.6944 121.432 28.886 121.244 29.0487C121.056 29.2114 120.828 29.3343 120.565 29.4247C120.301 29.5115 120.011 29.5548 119.693 29.5548C119.375 29.5548 119.071 29.5187 118.735 29.45C118.399 29.3813 118.113 29.2873 117.871 29.1716L117.983 28.7956C118.262 28.9185 118.533 29.0089 118.804 29.074C119.075 29.1391 119.364 29.1716 119.675 29.1716C120.203 29.1716 120.619 29.0451 120.93 28.792C121.241 28.5389 121.396 28.1919 121.396 27.7544C121.396 27.5194 121.349 27.3169 121.255 27.147C121.161 26.9771 121.038 26.8289 120.886 26.6951C120.734 26.5613 120.561 26.4457 120.366 26.3444C120.17 26.2432 119.968 26.142 119.758 26.0407C119.509 25.9178 119.277 25.7985 119.064 25.6828C118.851 25.5635 118.67 25.4334 118.518 25.2887C118.366 25.1441 118.247 24.9814 118.164 24.7971C118.081 24.6163 118.037 24.3994 118.037 24.1499C118.037 23.8788 118.088 23.6474 118.193 23.4449C118.298 23.2461 118.439 23.0834 118.612 22.9532C118.786 22.8231 118.992 22.7291 119.22 22.664C119.447 22.5989 119.69 22.5664 119.939 22.5664C120.243 22.5664 120.536 22.5989 120.814 22.6676C121.092 22.7363 121.345 22.8267 121.57 22.9424L121.468 23.3256C121.226 23.2099 120.977 23.1196 120.724 23.0509C120.471 22.9822 120.217 22.9496 119.961 22.9496C119.534 22.9496 119.183 23.0472 118.901 23.2389C118.623 23.4305 118.482 23.7269 118.482 24.1246C118.482 24.3198 118.511 24.4861 118.569 24.6271C118.627 24.7645 118.713 24.8911 118.826 25.0031C118.941 25.1152 119.082 25.2201 119.256 25.3213C119.429 25.4225 119.632 25.5274 119.867 25.6358C120.084 25.7371 120.308 25.8491 120.539 25.9757C120.771 26.0986 120.984 26.2468 121.176 26.4095C121.367 26.5758 121.526 26.7674 121.653 26.9807C121.779 27.1976 121.841 27.4507 121.841 27.7399Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3350_105015">
          <rect width="123.579" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
