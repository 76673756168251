import React from 'react';

const WelcomeImage = () => (
  <svg width="370" height="201" viewBox="0 0 370 201" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2014_2282)">
      <circle opacity="0.5" cx="185" cy="100.5" r="92" fill="#E6F3FE" />
      <ellipse opacity="0.3" cx="185" cy="100.997" rx="73.9744" ry="73.5032" fill="#B5DCFC" />
      <g filter="url(#filter0_d_2014_2282)">
        <rect x="135" y="50.5" width="100" height="100" rx="50" fill="#3AA2F8" style={{ mixBlendMode: 'multiply' }} />
        <mask
          id="mask0_2014_2282"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="150"
          y="65"
          width="70"
          height="71"
        >
          <g clip-path="url(#clip1_2014_2282)">
            <path
              d="M183.268 124.695C183.325 124.639 183.375 124.57 183.444 124.52C183.682 124.319 183.958 124.175 184.234 124.081L184.692 105.651V95.1224C184.341 95.0659 184.002 94.9279 183.714 94.6895L166.012 110.427C166.289 110.891 166.364 111.424 166.245 111.926L183.262 124.689L183.268 124.695Z"
              fill="white"
            />
            <path
              d="M174.734 89.1172L183.224 92.1479C183.343 91.9283 183.5 91.7275 183.701 91.5581C183.996 91.3134 184.341 91.1691 184.692 91.1126V82.4722C183.876 82.3907 183.086 82.0079 182.521 81.3302C182.308 81.0792 182.157 80.7969 182.032 80.502L166.47 85.3524C166.502 85.5343 166.514 85.7226 166.502 85.9045L174.734 89.111V89.1172Z"
              fill="white"
            />
            <path
              d="M165.711 87.4296C165.378 87.7057 164.989 87.8689 164.588 87.9254V109.467C164.964 109.523 165.316 109.686 165.617 109.95L183.305 94.219C183.017 93.7672 182.923 93.2464 183.017 92.7444L173.354 89.2933L166.144 86.9214C166.025 87.1096 165.887 87.2791 165.705 87.4296H165.711Z"
              fill="white"
            />
            <path
              d="M214.467 118.897C221.168 108.168 221.551 94.4007 215.119 83.2253L207.972 85.5721C208.016 86.2561 207.746 86.9526 207.182 87.4294C206.849 87.7055 206.46 87.8687 206.058 87.9252V109.466C206.529 109.542 206.974 109.768 207.307 110.163C207.909 110.885 207.922 111.889 207.414 112.623L214.467 118.897Z"
              fill="white"
            />
            <path
              d="M186.299 94.6895C186.004 94.9279 185.665 95.0722 185.32 95.1224V103.932L185.903 124.131C186.211 124.256 186.499 124.438 186.744 124.695L203.761 111.933C203.642 111.424 203.724 110.885 203.994 110.433L186.292 94.6957L186.299 94.6895Z"
              fill="white"
            />
            <path
              d="M185.32 76.0284C186.136 76.1099 186.926 76.4927 187.491 77.1704C188.15 77.961 188.357 78.9712 188.162 79.9062L203.717 84.7566C203.849 84.4742 204.044 84.2107 204.301 83.9973C205.249 83.2004 206.66 83.3259 207.457 84.2797C207.476 84.3048 207.489 84.3299 207.507 84.355L214.209 81.7447C213.431 80.5399 212.584 79.3603 211.636 78.2308C204.847 70.1112 195.134 65.8883 185.32 65.7942V76.0221V76.0284Z"
              fill="white"
            />
            <path
              d="M162.499 84.3486C162.599 84.2232 162.706 84.1039 162.838 83.9973C163.785 83.2004 165.197 83.3259 165.994 84.2796C166.119 84.4302 166.214 84.5934 166.295 84.7628L181.857 79.9124C181.624 78.7829 181.988 77.5593 182.936 76.7687C183.457 76.3357 184.072 76.091 184.705 76.0283V65.7878C176.943 65.8506 169.156 68.5111 162.737 73.876C159.97 76.1914 157.655 78.8457 155.797 81.7321L162.505 84.3486H162.499Z"
              fill="white"
            />
            <path
              d="M206.98 113.081C206.12 113.74 204.884 113.62 204.182 112.78C204.119 112.704 204.069 112.616 204.012 112.535L187.127 125.197C187.698 126.201 187.491 127.5 186.568 128.272C186.399 128.41 186.217 128.517 186.029 128.605L186.223 135.212C193.684 134.955 201.113 132.313 207.281 127.149C210.005 124.871 212.282 122.267 214.121 119.443L206.974 113.087L206.98 113.081Z"
              fill="white"
            />
            <path
              d="M184.116 128.655C183.745 128.51 183.4 128.285 183.131 127.958C182.459 127.155 182.396 126.051 182.886 125.191L166 112.529C165.887 112.711 165.756 112.886 165.58 113.031C164.833 113.652 163.773 113.652 163.032 113.081L155.898 119.425C156.651 120.579 157.473 121.709 158.376 122.788C164.996 130.7 174.396 134.911 183.953 135.206L184.116 128.655Z"
              fill="white"
            />
            <path
              d="M163.955 109.466V87.9252C163.428 87.8499 162.919 87.5926 162.549 87.1534C162.166 86.6953 162.003 86.1306 162.035 85.5784L154.881 83.2253C148.613 94.1435 148.676 107.91 155.553 118.897L162.606 112.629C162.016 111.776 162.154 110.596 162.963 109.912C163.258 109.667 163.603 109.523 163.955 109.466Z"
              fill="white"
            />
            <path
              d="M204.439 109.906C204.734 109.661 205.079 109.517 205.43 109.46V87.9191C204.903 87.8438 204.395 87.5865 204.025 87.1473C203.962 87.072 203.918 86.9967 203.868 86.9214L196.658 89.2933L186.995 92.7444C187.089 93.2526 186.995 93.7797 186.707 94.219L204.395 109.944C204.395 109.944 204.42 109.918 204.433 109.906H204.439Z"
              fill="white"
            />
            <path
              d="M187.98 80.5082C187.786 80.9663 187.497 81.3992 187.089 81.7381C186.569 82.171 185.954 82.4158 185.32 82.4785V91.1189C185.79 91.1942 186.236 91.4201 186.569 91.8154C186.656 91.9221 186.732 92.035 186.794 92.1479L195.278 89.1172L203.517 85.9045C203.498 85.7163 203.517 85.5343 203.542 85.3524L187.98 80.502V80.5082Z"
              fill="white"
            />
          </g>
        </mask>
        <g mask="url(#mask0_2014_2282)">
          <rect x="146.017" y="60.6694" width="78.8136" height="80.5085" fill="white" />
        </g>
      </g>
      <g filter="url(#filter1_d_2014_2282)">
        <rect x="250" y="32.5" width="116" height="74" rx="6" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M282.951 42.5C293.87 42.5 302.847 50.801 303.897 61.4275C303.962 62.0879 303.428 62.6416 302.764 62.6416L294.449 62.6416C293.84 62.6416 293.341 62.1737 293.228 61.5767C292.317 56.7372 288.063 53.0756 282.951 53.0756C279.216 53.0756 275.939 55.0306 274.089 57.9716C273.749 58.5123 273.065 58.7618 272.486 58.4919L264.997 55.0049C264.408 54.731 264.153 54.029 264.464 53.4601C268.035 46.9299 274.975 42.5 282.951 42.5Z"
          fill="#84C5FA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M264.485 56.6192C263.867 56.3318 263.133 56.6292 262.932 57.2791C262.326 59.24 262 61.3234 262 63.4828C262 75.0903 271.424 84.5001 283.049 84.5001C293.967 84.5001 302.944 76.1991 303.995 65.5726C304.06 64.9122 303.526 64.3585 302.861 64.3585L294.546 64.3585C293.938 64.3585 293.438 64.8265 293.326 65.4234C292.415 70.2629 288.16 73.9245 283.049 73.9245C277.273 73.9245 272.591 69.2496 272.591 63.4828C272.591 62.8243 272.652 62.18 272.769 61.5554C272.875 60.9901 272.615 60.4048 272.093 60.1617L264.485 56.6192Z"
          fill="#B5DCFC"
        />
        <path
          d="M275.915 54.8439C276.23 55.1534 276.733 55.1697 277.093 54.912C278.786 53.7009 280.872 52.9863 283.128 52.9863C288.235 52.9863 292.471 56.6507 293.255 61.447C293.325 61.8781 293.692 62.2143 294.137 62.2143L303.162 62.2143C303.647 62.2143 304.037 61.8169 303.997 61.342C303.111 50.7914 294.106 42.5 283.128 42.5C277.957 42.5 273.224 44.339 269.571 47.3871C269.202 47.6954 269.192 48.2457 269.535 48.5823L275.915 54.8439Z"
          fill="#84C5FA"
        />
        <path
          d="M283.035 73.8177C288.165 73.8177 292.42 70.085 293.207 65.199C293.277 64.7599 293.646 64.4174 294.093 64.4174L303.158 64.4174C303.646 64.4174 304.037 64.8222 303.997 65.306C303.107 76.0537 294.062 84.4999 283.035 84.4999C271.417 84.4999 262 75.1261 262 63.5629C262 58.4237 263.86 53.7172 266.947 50.0737C267.262 49.702 267.827 49.6911 268.172 50.0349L274.585 56.418C274.9 56.7314 274.918 57.2303 274.659 57.5904C273.446 59.2724 272.732 61.3346 272.732 63.5629C272.732 69.2265 277.345 73.8177 283.035 73.8177Z"
          fill="#B5DCFC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M312 63.5487C312 52.6302 320.301 43.6534 330.927 42.6028C331.588 42.5375 332.142 43.0717 332.142 43.7363V52.0514C332.142 52.6597 331.674 53.1593 331.077 53.2717C326.237 54.1826 322.576 58.4375 322.576 63.5487C322.576 67.2835 324.531 70.5611 327.472 72.4109C328.012 72.751 328.262 73.4347 327.992 74.0142L324.505 81.5033C324.231 82.0917 323.529 82.3467 322.96 82.0356C316.43 78.4649 312 71.5249 312 63.5487Z"
          fill="#84C5FA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M326.119 82.0153C325.832 82.6326 326.129 83.3672 326.779 83.568C328.74 84.1738 330.823 84.5 332.983 84.5C344.59 84.5 354 75.0762 354 63.4513C354 52.5328 345.699 43.556 335.073 42.5054C334.412 42.4401 333.859 42.9743 333.859 43.6389V51.954C333.859 52.5623 334.326 53.0619 334.923 53.1743C339.763 54.0852 343.425 58.34 343.425 63.4513C343.425 69.2267 338.75 73.9086 332.983 73.9086C332.324 73.9086 331.68 73.8476 331.055 73.7308C330.49 73.6252 329.905 73.885 329.662 74.4069L326.119 82.0153Z"
          fill="#B5DCFC"
        />
        <path
          d="M324.344 70.5851C324.653 70.2698 324.67 69.7672 324.412 69.4069C323.201 67.7136 322.486 65.6281 322.486 63.3725C322.486 58.2647 326.151 54.029 330.947 53.2454C331.378 53.1749 331.714 52.8083 331.714 52.3635V43.3381C331.714 42.8526 331.317 42.4629 330.842 42.5028C320.291 43.3888 312 52.3941 312 63.3725C312 68.5428 313.839 73.2756 316.887 76.9287C317.195 77.2982 317.746 77.308 318.082 76.965L324.344 70.5851Z"
          fill="#84C5FA"
        />
        <path
          d="M343.318 63.4655C343.318 58.335 339.585 54.0803 334.699 53.2932C334.26 53.2225 333.917 52.8542 333.917 52.4074V43.3419C333.917 42.8542 334.322 42.4628 334.806 42.5028C345.554 43.3927 354 52.4382 354 63.4655C354 75.0826 344.626 84.5 333.063 84.5C327.924 84.5 323.217 82.6399 319.574 79.5533C319.202 79.2384 319.191 78.6734 319.535 78.328L325.918 71.9152C326.231 71.6003 326.73 71.5818 327.09 71.8414C328.772 73.0541 330.835 73.7681 333.063 73.7681C338.726 73.7681 343.318 69.1555 343.318 63.4655Z"
          fill="#B5DCFC"
        />
        <rect x="262" y="90.5" width="6" height="6" rx="3" fill="#84C5FA" />
        <rect opacity="0.5" x="270" y="91.5" width="32" height="4" rx="2" fill="#B5DCFC" />
        <rect x="312" y="90.5" width="6" height="6" rx="3" fill="#84C5FA" />
        <rect opacity="0.5" x="320" y="91.5" width="32" height="4" rx="2" fill="#B5DCFC" />
        <rect x="250.5" y="33" width="115" height="73" rx="5.5" stroke="url(#paint0_linear_2014_2282)" />
      </g>
      <g filter="url(#filter2_d_2014_2282)">
        <rect x="18" y="94.5" width="104" height="32" rx="6" fill="white" />
        <rect x="26" y="101.5" width="18" height="18" rx="2.77333" fill="#B5DCFC" />
        <path
          d="M30 115.5V114.5C30 113.395 30.8954 112.5 32 112.5H38C39.1046 112.5 40 113.395 40 114.5V115.5"
          stroke="white"
          stroke-width="0.9"
          stroke-linecap="round"
        />
        <circle
          cx="35"
          cy="108.5"
          r="2"
          stroke="white"
          stroke-width="0.9"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect opacity="0.5" x="52" y="103.833" width="62" height="6" rx="3" fill="#B5DCFC" />
        <rect opacity="0.5" x="52" y="111.833" width="22.5455" height="6" rx="3" fill="#B5DCFC" />
      </g>
      <g filter="url(#filter3_d_2014_2282)">
        <rect x="18" y="134.5" width="104" height="32" rx="6" fill="white" />
        <rect x="26" y="141.5" width="18" height="18" rx="2.77333" fill="#B5DCFC" />
        <path
          d="M30 155.5V154.5C30 153.395 30.8954 152.5 32 152.5H38C39.1046 152.5 40 153.395 40 154.5V155.5"
          stroke="white"
          stroke-width="0.9"
          stroke-linecap="round"
        />
        <circle
          cx="35"
          cy="148.5"
          r="2"
          stroke="white"
          stroke-width="0.9"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect opacity="0.5" x="52" y="143.833" width="62" height="6" rx="3" fill="#B5DCFC" />
        <rect opacity="0.5" x="52" y="151.833" width="22.5455" height="6" rx="3" fill="#B5DCFC" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_2014_2282"
        x="35.0847"
        y="8.21191"
        width="306.068"
        height="169.627"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.152941 0 0 0 0 0.270588 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2014_2282" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2014_2282" result="shape" />
      </filter>
      <filter
        id="filter1_d_2014_2282"
        x="247"
        y="29.5"
        width="124"
        height="82"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2014_2282" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2014_2282" result="shape" />
      </filter>
      <filter
        id="filter2_d_2014_2282"
        x="15"
        y="91.5"
        width="112"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2014_2282" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2014_2282" result="shape" />
      </filter>
      <filter
        id="filter3_d_2014_2282"
        x="15"
        y="131.5"
        width="112"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0889583 0 0 0 0 0.146387 0 0 0 0 0.35 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2014_2282" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2014_2282" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_2014_2282"
        x1="250"
        y1="32.5"
        x2="353.804"
        y2="117.33"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5FAFF" />
        <stop offset="1" stop-color="#F5FAFF" />
      </linearGradient>
      <clipPath id="clip0_2014_2282">
        <rect width="370" height="200" fill="white" transform="translate(0 0.5)" />
      </clipPath>
      <clipPath id="clip1_2014_2282">
        <rect width="69.4242" height="69.4242" fill="white" transform="translate(150.288 65.7878)" />
      </clipPath>
    </defs>
  </svg>
);

export default WelcomeImage;
